import { toLitiumMediaUrlKey } from '@distancify/drapejs-litium/lib/keys';
import { computed, inject } from 'vue';

export default function useBanners(bannersField, cssPrefix: string) {
  if (!bannersField) return { banners: [] };

  const toLitiumMediaUrl = inject(toLitiumMediaUrlKey, (url: string, opts: any) => '');
  const banners = computed(() => bannersField.map(mapBanner));

  function mapBanner(b) {
    const overlays = overlayPositions(b);
    return {
      key: Math.random().toString(36).substring(2),
      imageUrl: bannerImageUrl(b.Image),
      bgImage: bannerImage(b.Image),
      aspectRatio: aspectRatio(b),
      element: getWrappingElement(b),
      link: bannerLink(b),
      body: b.OverlayBody,
      overlayClasses: Object.keys(overlays).map((position) => ({
        name: position,
        items: overlays[position].map((i) => getOverlayClassFor(i)),
      })),
    };
  }

  function getOverlayClassFor(item) {
    const classes = [
      `${cssPrefix}__overlay-item`,
      `${cssPrefix}__overlay-item--phone-text-${item.banner.OverlayTextAlignmentPhone?.value || 'center'
      }`,
      `${cssPrefix}__overlay-item--desktop-text-${item.banner.OverlayTextAlignmentDesktop?.value || 'center'
      }`,
    ];
    item.showOn.forEach((device) => {
      classes.push(`${cssPrefix}__overlay-item--${device}`);
    });
    return classes;
  }

  return {
    banners,
  };

  function overlayPositions(banner) {
    const res = {} as { string: string };
    const positions = [] as Array<string>;

    const posPhone = banner.OverlayPositionPhone?.value || 'center';
    const posDesktop = banner.OverlayPositionDesktop?.value || 'center';

    function addPosition(positionValue) {
      if (positionValue === 'hidden') {
        return;
      }
      if (positionValue && positions.indexOf(positionValue) < 0) {
        positions.push(positionValue);
      }
    }

    addPosition(posPhone);
    addPosition(posDesktop);

    positions.forEach((pos) => {
      if (!res[pos]) {
        res[pos] = [];
      }
      const showOn = [] as Array<string>;
      if (pos === posPhone) {
        showOn.push('phone');
      }
      if (pos === posDesktop) {
        showOn.push('desktop');
      }
      res[pos].push({
        showOn,
        banner,
      });
    });

    return res;
  }

  function bannerImageUrl(image) {
    return toLitiumMediaUrl(image?.id, { maxWidth: 1000 });
  }

  function bannerImage(image) {
    return `background-image: url(${bannerImageUrl(image) || ''})`;
  }

  function aspectRatio(banner) {
    return `padding-top: ${Math.round(((banner.Image?.height || 1) / (banner.Image?.width || 1)) * 100)}%`;
  }

  function getWrappingElement(banner) {
    return bannerLink(banner) ? 'router-link' : 'div';
  }

  function bannerLink(banner) {
    return banner.CompositeLinkPage?.url
      || banner.CompositeLinkProduct?.url
      || banner.CompositeLinkCategory?.url
      || banner.CompositeLinkSuffix
      || undefined;
  }
}
